<template>
    <div class="box">
        <Add :orderId="orderId"></Add>
    </div>
    
</template>

<script>
import add from '../../scrm_pc/order/orderAdd'  
export default {
    components: {
        'Add': add  //将别名demo 变成 组件 Demo
    },
    data() {
        return {
            orderId: ""
        }
    },
    mounted: function(){
        // console.log(this.$route.query.id);
        // this.orderId = this.$route.query.id;
    },
}

</script>
<style scoped>
    .box{
        height: 100vh;
        background-color: #fff;
        padding: 15px;
    }
</style>
